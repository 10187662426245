p.dashboard-header {
    margin-top: 0px;
    margin-bottom: 0px;
    span {
        cursor: pointer;
    }
}
p.dashboard-actions{
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    span {
        cursor: pointer;
    }
}
.copyToClip:active{
    animation: copyToClip 0.2s;
}
@keyframes copyToClip {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.4);
    }
    60%{
        transform: scale(1.1);
    }
    70%{
        transform: scale(1.2);
    }
    80%{
        transform: scale(1);
    }
    90%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
}
.planDetails{
    font-size: 12px;
}

#logoModal:focus {
    outline: none;
}

#contained-button-file {
    display: none;
}

#logoPic {
    width: 130px;
    height: 130px;
}

.logoPic {
    margin: 50px;
    padding-left: 50px;
}


#load_gif_logo {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    background: url("https://cdn.lowgif.com/small/ee5eaba393614b5e-pehliseedhi-suitable-candidate-suitable-job.gif") no-repeat center center rgba(0, 0, 0, 0.25);
}

#logoPara {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#edit_para_channel {
    span {
        color: blue;
        margin-right: 60px;
        cursor: pointer;
    }
}