.auth-background {
    background-image: url('../../../assets/img/villa.background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100vh;
}
.legalAgreement{
    display: inline-block;
    font-size: 12px;
}

@media only screen and (max-width: 959px) {
    .auth-background {
        background-image: url('');
        width: 0px;
        height: 0px;
    }
    #auth_panel {
        width: 100vh;
        height: 100vh;
    }
}