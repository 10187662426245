.footer-flex{
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    flex-wrap: wrap;

}

.footer-menu-item{
    padding-left: 15px;
    padding-right: 15px;

    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #ffffff;

    cursor: pointer;


}
.copyright-footer{
    font-family: 'Nunito', sans-serif;
    font-size: 12px;
    color: #ffffff;

    cursor: pointer;
}