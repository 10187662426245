.displayName {
    width: 50px;
    height: 50px;
    margin-right: 8px;
    border-radius: 50%;
}

p {
    font-size: 20px;
    font-weight: 500;
}
.customerLegalAgreement{
    display: inline-block;
    font-size: 10px;

}