.title{
    font-family: 'Nunito', sans-serif;
    padding-bottom: 21px;
}
.flex-how{
    display: flex;
    flex-direction: row;
    justify-content: center;

    height: 50%;
    position: relative;


}
.flex-how-rev{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    height: 50%;
    position: relative;

}

.img-how{
    object-fit: contain;
    padding: 10px;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;

    background-size: auto;
    background-repeat:no-repeat;

}
.words-how{
    height: 100%;
    position: relative;
    width: 40%;
    text-align: left;

 }
.words-how p{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    font-family: 'Nunito', sans-serif;
    font-size: 17px;

}
.words-how-rev{
    height: 100%;
    position: relative;
    width: 40%;
    text-align: right;

}
.words-how-rev p{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    font-family: 'Nunito', sans-serif;
    font-size: 17px;
}



@media (max-width: 900px) {
    .flex-how{
        display: flex;
        flex-direction: column;
        justify-content: center;

        height: 60%;
        position: relative;


    }
    .flex-how-rev{
        display: flex;
        flex-direction: column;
        justify-content: center;

        height: 60%;
        position: relative;

    }
    .words-how{
        height: 100%;
        width: 100%;
        text-align: center;
    }
    .words-how p{
        width: 90%;
    }
    .words-how-rev{
        height: 100%;
        width: 100%;
        text-align: center;
    }
    .words-how-rev p{
        width: 90%;
    }

}